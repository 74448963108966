<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
    >
      <template #datatableTop="data">
        <Generate
          :items="data.selected"
          :types="types"
          v-if="permissionChecker('print')"
        ></Generate>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/finance/invoices/AddItem.vue";
import EditItem from "@/own/components/finance/invoices/EditItem";
import Generate from "@/own/components/finance/invoices/Generate.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/invoices.module";
export default {
  name: "Invoices",
  components: { DataTable, Generate },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/cod/invoices/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVOICESTableFilters",
      getTableState: "getINVOICESTableState",
      getTableData: "getINVOICESTableData",
      getTableHeaders: "getINVOICESTableHeaders",
      getTableProperties: "getINVOICESTableProperties",
      getTableExportUrl: "getINVOICESExportUrl",
      getTablePermissions: "getINVOICESTablePermissions",
    },
    urls: {
      delete: `${process.env.VUE_APP_BASE_URL}/cod/invoices/destroy`,
      activate: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/activate`,
      status: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/status`,
    },
  }),
  methods: {
    permissionChecker(permType) {
      // console.log(this.store_names.getTablePermissions, permType);
      let result = false;
      this.$store.getters[this.store_names.getTablePermissions].forEach(
        (element) => {
          if (element.name == permType) {
            result = true;
          }
        }
      );
      return result;
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    types: function () {
      let typies = [
        // { name: "shipping label", value: "label" },
        { name: "barcode", value: "barcode" },
        // { name: "invoice", value: "invoice" },
        // { name: "manifest", value: "manifest" },
        // { name: "packing summary", value: "packing_summary" },
        // { name: "packing slip", value: "packing_slip" }
      ];
      return typies;
      // return this.$store.getters.getORDERSPrintTypes;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
