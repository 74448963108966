<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="mr-md-2 mr-sm-0">
        <b-button
          class="font-weight-bolder white--text green px-8 text-h6"
          v-bind="attrs"
          v-on="on"
          block
          variant=""
        >
          Print
        </b-button>
      </div>
    </template>
    <v-list>
      <v-list-item v-for="(print, index) in types" :key="index">
        <v-list-item-title
          ><v-btn block @click="onPrint(print.value)">{{
            print.name
          }}</v-btn></v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Print",
  props: ["items", "types"],

  methods: {
    onPrint(val) {
      if (this.items.length > 0) {
        let isReportShown = { availableAmount: 12 };
        if (isReportShown.availableAmount > 0) {
          this.$store.commit(SET_PAGE_LOADING, true);
          let data = { type: val, ids: this.itemsCol };
          ApiService.post("/fulfillment/skus/print", data)
            .then((response) => {
              const link = document.createElement("a");
              link.href = response.data.path;
              link.target = "_blank";
              link.download = "orders.csv";
              link.click();
              setTimeout(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              }, 2500);
            })
            .catch(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: "Warning",
            text: "Can not create report about print!",
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
  computed: {
    itemsCol: function () {
      return this.items.map((item) => item.id);
    },
  },
};
</script>
